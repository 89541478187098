<template>
  <v-snackbar
      v-model="snackbar"
      :top="$vuetify.breakpoint.mdAndUp"
      :bottom="$vuetify.breakpoint.smAndDown"
      :vertical="$vuetify.breakpoint.smAndDown"
  >
   {{ title}}
    <template #action="{ attrs }">
      <v-btn text v-bind="attrs" @click="snackbar = false">
        {{ $t("snackbar.close") }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Snackbar',
  data() {
    return {
      snackbar: false,
      title: '',
    };
  },
  created() {
    if (this.$route.query.allUsersFromThisDeviceRevoked) {
      this.snackbar = true;
      this.title = this.$t('snackbar.allUsersFromThisDeviceRevoked');
    }
    if (this.$route.query.userTokensRevoked) {
      this.snackbar = true;
      this.title = this.$t('snackbar.userTokensRevoked');
    }
  },
};

</script>
