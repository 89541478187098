<template>
  <v-container :fluid="$vuetify.breakpoint.smAndDown">

  <v-row class="mt-16">
    <div v-if="activeMethod === null">
      <v-progress-circular color="primary" indeterminate />
    </div>
    <v-col v-else>
      <component
          :is="methodMapping[activeMethod]"
          :twoData="twofactor"
          @stepBack="$emit('stepBackAgain')"
      />
      <div v-if="(twofactor.twoFactor.length > 1)" class="mt-6 ma-3">
        <v-card class="px-4">
          <v-card-title class="subtitle-1 font-weight-medium text--secondary">
            {{ $t('login.choose') }}:
          </v-card-title>
          <v-divider/>
          <v-card-actions class="">
            <v-row no-gutters >
              <v-btn
                  color="primary-gradient-border"
                  class="text-uppercase mx-1"
                  :class="{'ml-0': i === 0}"
                  v-for="(method, i) in twofactor.twoFactor"
                  :key="method"
                  @click="() => setActiveMethod(method)"
                  outlined
              >
                {{ method }}
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </div>
    </v-col>
  </v-row>
  </v-container>
</template>

<script>
import { getCookie, setCookie } from '@/util/cookie';
import Software from './Software.vue';
import Hardware from './Hardware.vue';

export default {
  methods: {
    setActiveMethod(method) {
      this.activeMethod = method;
      setCookie('zwm-auth-activeAuthMethod', method, 9999);
    },
  },
  props: {
    twofactor: {
      type: Object,
    },
  },
  data() {
    return {
      activeMethod: null,
      methodMapping: {
        totp: Software,
        webauthn: Hardware,
      },
    };
  },
  components: {
    Software,
    Hardware,
  },

  mounted() {
    if (this.twofactor.id === null || !this.twofactor.twoFactor.length) {
      this.$emit('stepBackAgain');
    }
    const twoFactorData = JSON.parse(JSON.stringify(this.twofactor));
    if (twoFactorData.twoFactor.length === 1) {
      [this.activeMethod] = twoFactorData.twoFactor;
    } else if (getCookie('zwm-auth-activeAuthMethod')) {
      this.activeMethod = getCookie('zwm-auth-activeAuthMethod');
    } else {
      this.activeMethod = 'totp';
    }
  },
};
</script>
