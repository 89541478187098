<template>
  <v-row v-if="$vuetify.breakpoint.mdAndUp" justify="center">
    <v-col class="pa-0" cols="12" lg="9" xl="7" md="12">
      <v-btn-toggle
        v-model="language"
        class="float-right"
        data-cy="langToggle"
        dense
        group
        mandatory
        @change="setLocale"
      >
        <v-btn
          v-for="item in languages"
          v-bind:key="item.key"
          :value="item.value"
          data-cy="langBtn"
          class="ma-0"
          >{{ item.key }}
        </v-btn>
      </v-btn-toggle>
    </v-col>
  </v-row>

  <v-select
    v-else-if="mobile"
    v-model="language"
    :items="languages"
    class="custom text-subtitle-2"
    data-cy="select-input"
    item-text="key"
    item-value="value"
    @change="setLocale"
  >
  </v-select>
</template>

<script>
import { localize } from 'vee-validate';
import { getCookie, setCookie } from '@/util/cookie';
import MetaTagsMixin from '@/mixins/MetaTagsMixin';

export default {
  props: {
    mobile: { type: Boolean, default: false },
  },
  mixins: [MetaTagsMixin],
  data() {
    return {
      language: '',
      languages: [
        { key: 'EST', value: 'et' },
        { key: 'ENG', value: 'en' },
        { key: 'FIN', value: 'fi' },
        { key: 'RUS', value: 'ru' },
      ],
    };
  },
  mounted() {
    this.language = process.env.VUE_APP_I18N_LOCALE;
    if (this.$route.params.lang) {
      this.language = this.$route.params.lang;
    } else if (navigator.language) {
      this.language = navigator.language.slice(0, 2);
    } else if (getCookie('zwma-lang')) {
      this.language = getCookie('zwma-lang');
    }

    if (this.$route.params.lang !== this.language) {
      this.$router.replace({ name: 'Auth', params: { lang: this.language } });
    }
  },
  methods: {
    setLocale() {
      setCookie('zwma-lang', this.language, 30, process.env.VUE_APP_NEW_WEBMAIL);
      if (this.$route.params.lang !== this.language) {
        this.$router.replace({ name: 'Auth', params: { lang: this.language } });
      }
      this.$i18n.locale = this.language;
      localize(this.language);
      this.setMetaTags();
    },
  },
};
</script>
