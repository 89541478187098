export const setCookie = (cname, cvalue, exdays, domain = null) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;

  const cDomain = (() => {
    if (!domain) return '';

    let hDomain = domain;

    if (hDomain.substr(0, 4) === 'http') {
      hDomain = hDomain.replace('https://', '.').replace('http://', '.');
    }
    if (hDomain.substr(0, 2) === '//') {
      hDomain = hDomain.replace('//', '.');
    }

    if (hDomain.includes(':')) {
      const index = hDomain.indexOf(':');
      hDomain = hDomain.substr(0, index);
    }

    return `domain=${hDomain};`;
  })();

  document.cookie = `${cname}=${cvalue};${expires};${cDomain}path=/`;
};

export const getCookie = (cname) => {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
};

export default {
  setCookie,
  getCookie,
};
