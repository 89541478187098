<template>
  <div :class="['zwa-account-select',{'list-scroll':accounts && accounts.length > 5}]" >
    <v-row
      v-for="(account, i) in accounts"
      :key="`account${i}`"
      class="account mx-0 pa-0"
      align="center"
      v-ripple
      @click="login(account)"
    >
      <!--  Display chosen avatar or else initials: -->
      <v-col class="shrink pa-0">
        <v-avatar class="avatar" color="primary-gradient-border" size="40">
          <img v-if="account.avatar" :src="account.avatar" :alt="getInitials(account)" />
          <span v-else class="body-1 font-weight-medium primary-gradient--text">
            {{ getInitials(account) }}
          </span>
        </v-avatar>
      </v-col>

      <v-col>
        <span v-if="account.email" class="text-h6 font-weight-regular d-block account__email ">
          {{ account.email }}
        </span>
      </v-col>
      <v-col class="shrink pa-0">
        <v-icon >mdi-arrow-right</v-icon>
      </v-col>
    </v-row>

  </div>
</template>

<script>
export default {
  props: {
    accounts: Array,
  },
  methods: {
    login(account) {
      window.location.assign(`${process.env.VUE_APP_NEW_WEBMAIL}${account.path}`);
    },
    getInitials(account) {
      let initialsConstruct = account.email;

      if (account.name) {
        initialsConstruct = account.name;
      }

      // eslint-disable-next-line prefer-regex-literals
      const regex = new RegExp('(^|[ -\\/:-@\\[-`\\{-\\~])[^ -\\/:-@\\[-`\\{-\\~]', 'g');
      const initials = initialsConstruct.match(regex).map((match) => match[match.length - 1]);
      const segments = [];
      segments.push(initials[0]);
      if (initials.length > 1) {
        segments.push(initials[initials.length - 1]);
      }

      return segments.join('').toUpperCase();
    },

  },
};
</script>
