// eslint-disable-next-line no-restricted-imports
import api from '@/services/api';
import { decodeChallengeOptions, encodeChallengeResponse } from '@/util/webauthn';

/**
 * Login method for client.
 * Request assertion from server, signs the assertion with the user credentials,
 * and sends it back to server for verification.
 * @typedef {
 *  {authenticationChallenge: string, authenticationAssertion: string}
 * } AuthenticationContext
 *
 * @param {String} authenticatorAttachment
 * @param {String|Undefined} mailId of the user
 * @param {AuthenticationContext} context The context where to send the requests
 * @returns {Promise<AxiosResponse<any>>} response status from server
 */
const authenticateForContext = async (authenticatorAttachment, mailId, context) => {
  const challengeUrlParams = new URLSearchParams({
    authenticatorAttachment,
  });
  if (mailId) challengeUrlParams.append('id', mailId);
  // Request assertion from server
  const { data: challengeData } = await api.get(
    `${context.authenticationChallenge}?${challengeUrlParams.toString()}`,
  );
  if (!challengeData.success) {
    throw new Error('Failed to fetch registration data from server');
  }

  const assertionResult = await navigator.credentials.get({
    publicKey: decodeChallengeOptions(challengeData.authenticationOptions),
  });

  // process response
  const assertionRequestPayload = encodeChallengeResponse(
    challengeData.authenticationOptions.challenge,
    assertionResult,
  );

  return api.post(
    context.authenticationAssertion,
    {
      ...assertionRequestPayload,
      id: mailId,
    },
  );
};

export default {
  /**
   * Login method for client.
   * Request assertion from server, signs the assertion with the user credentials,
   * and sends it back to server for verification.
   * @param {"platform"|"cross-platform"} authenticatorAttachment
   * @param {String} mailId of the user
   * @returns {Promise<AxiosResponse<any>>} response status from server
   */
  async login(authenticatorAttachment, mailId) {
    console.debug('Starting WebAuthn 2FA login process');
    return authenticateForContext(
      authenticatorAttachment,
      mailId,
      {
        authenticationChallenge: '/auth/2fa/webauthn/authentication-challenge',
        authenticationAssertion: '/auth/2fa/webauthn/authentication-assertion',
      },
    );
  },
};
