<template>
  <div class="zwa-login">
    <Lang />
    <v-row justify="center">
      <v-col class="info-block hidden-sm-and-down" cols="12" lg="5" md="6" xl="4">
        <div class="info-block__info">
          <h1
            class="info-block__title"
            v-html="$t(`installation-specific.${installationTransKey}.title`)"
          ></h1>
          <p class="pt-3 pb-8">
            {{ $t(`installation-specific.${installationTransKey}.subtitle.text`) }}
            <a
              :href="$t(`installation-specific.${installationTransKey}.subtitle.link`)"
              target="_blank"
            >
              {{ $t(`installation-specific.${installationTransKey}.subtitle.link-text`) }}
            </a>
          </p>
          <hr style="border: 1px solid rgba(189, 189, 192, 0.05)" />
        </div>
        <v-img
          :class="accounts.length > 2 && activeStep !== 2 ? 'info-block__logo' : 'mt-12'"
          :src="emailImage"
          height="auto"
          width="auto"
        />
      </v-col>
      <v-col class="authentication-block" cols="12" lg="4" md="6" xl="3">
        <div>
          <v-img :src="webMailLogo" height="auto" width="10rem" />
          <h3 class="authentication-block__title">
            {{ $t('login.login') }}
          </h3>
          <div v-if="accounts.length && activeStep < 2 && !switchToLogin">
            <AccountSelection :accounts="accounts" />
            <v-row class="text-center authentication-block__info">
              <v-col align-self="center" cols="2" sm>
                <hr style="color: #eeeeee" />
              </v-col>
              <v-col cols="8" sm="auto">
                <span> {{ $t('login.login-with-email') }} </span></v-col
              >
              <v-col align-self="center" cols="2" sm>
                <hr />
              </v-col>
            </v-row>
          </div>
          <TwoFactor
            v-if="activeStep === 3"
            :twofactor="twoData"
            @stepBackAgain="activeStep = 1"
          ></TwoFactor>
          <Wildduck
            v-if="activeStep !== 3"
            @data="twofactor"
            @nextStep="activeStep += 1"
            @stepBack="activeStep -= 1"
          />
        </div>
      </v-col>
    </v-row>
    <AuthFooter v-if="$vuetify.breakpoint.mdAndUp" />
  </div>
</template>

<script>
import AuthFooter from '../components/AuthFooter.vue';
import Lang from '../components/Lang.vue';
import Wildduck from '../components/Wildduck.vue';
import TwoFactor from '../components/two-factor/TwoFactor.vue';
import AccountSelection from '../components/AccountSelection.vue';

export default {
  components: {
    AuthFooter,
    AccountSelection,
    Lang,
    Wildduck,
    TwoFactor,
  },
  data() {
    return {
      switchToLogin: false,
      activeStep: 1,
      accounts: [],
      twoData: {},
    };
  },

  created() {
    if (this.$route.query.reason && this.$route.query.reason.length) {
      if (this.$route.query.email && this.$route.query.email.length) {
        this.activeStep = 2;
      }
    }
    this.loadLoggedInAccounts();
  },
  methods: {
    async loadLoggedInAccounts() {
      try {
        const { data } = await this.$http.get('/auth/accounts');
        this.accounts = data;
      } catch (e) {
        console.error(e);
      }
    },
    twofactor(e) {
      this.twoData = e;
    },
  },
  computed: {
    webMailLogo() {
      return process.env.VUE_APP_WEBMAIL_LOGO || '/assets/zone_ee.svg';
    },
    emailImage() {
      return process.env.VUE_APP_WEBMAIL_IMAGE || '/assets/zone_login_new.png';
    },
  },
};
</script>
