import Vue from 'vue';

import { localize } from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import et from 'vee-validate/dist/locale/et.json';
import ru from 'vee-validate/dist/locale/ru.json';
import fi from 'vee-validate/dist/locale/fi.json';

import '@mdi/font/css/materialdesignicons.css';
import '@/scss/main.scss';

import Http from '@/plugins/http';
import Uri from '@/plugins/uri';

import vuetify from './plugins/vuetify';
import i18n from './i18n';
import router from './router';
import App from './App.vue';

localize({
  en,
  et,
  ru,
  fi,
});

Vue.use(Http);
Vue.use(Uri);

Vue.mixin({
  computed: {
    installationTransKey() {
      return process.env.VUE_APP_TRANSLATION_KEY || 'zone';
    },
  },
});
Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
