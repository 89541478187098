import router from '@/router';

const getApiBaseUrl = () => process.env.VUE_APP_API_BASE_URL;

export default {
  create(uri, opts = {}) {
    const { currentRoute } = router;
    let { index } = currentRoute.params;
    const { path, params } = currentRoute;
    if (typeof index === 'undefined') {
      index = '0';

      if (path === '/' && !Object.keys(params).length) {
        const matches = window.location.pathname.match(/^\/u\/([0-9]).*/);
        if (matches && matches.length) {
          [, index] = matches;
        }
      }
    }

    let route = `/u/${index}${uri || ''}`;

    if (opts.absolute) {
      route = `${getApiBaseUrl()}${route}`;
    }

    return route;
  },
};
