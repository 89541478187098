const MetaTagMixin = {
  methods: {
    setMetaTags() {
      if (!this?.$t) {
        console.debug('No translation function registered. Failed to set meta tags.');
        return;
      }
      // Remove any stale meta tags from the document using the key attribute we set below.
      Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(
        (el) => el.parentNode.removeChild(el),
      );

      const metaTags = ['description'];

      metaTags.forEach((metaTag) => {
        // Turn the meta tag definitions into actual elements in the head.
        console.debug(this.$t(`metaTags.${metaTag}`), `metaTags.${metaTag}`);
        if (this.$t(`metaTags.${metaTag}`) !== `metaTags.${metaTag}`) {
          const metaTagElement = document.createElement('meta');
          const ogTagElement = document.createElement('meta');
          metaTagElement.setAttribute('name', metaTag);
          metaTagElement.setAttribute('content', this.$t(`metaTags.${metaTag}`));
          ogTagElement.setAttribute('property', `og:${metaTag}`);
          ogTagElement.setAttribute('content', this.$t(`metaTags.${metaTag}`));

          // We use this to track which meta tags we create so we don't interfere with other ones.
          metaTagElement.setAttribute('data-vue-router-controlled', '');
          ogTagElement.setAttribute('data-vue-router-controlled', '');
          document.head.appendChild(metaTagElement);
          document.head.appendChild(ogTagElement);
        }
      });
    },
  },
};

export default MetaTagMixin;
