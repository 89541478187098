import axios from 'axios';
import i18n from '../i18n';

const HTTP_PAGE_EXPIRED = 419;

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  // headers: { 'Accept-Language': i18n.locale },
  withCredentials: true,
  withXSRFToken: true,
});

api.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
api.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers['Accept-Language'] = i18n.locale;
    return config;
  },
  (error) => Promise.reject(error),
);
api.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response) {
      if (err.response.status === HTTP_PAGE_EXPIRED) {
        // XSRF token has expired
        console.info('XSRF token has expired. Refreshing!');
        return api.get('/').then(() => api.request(err.config));
      }
    }

    return Promise.reject(err);
  },
);
export default api;
