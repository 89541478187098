<template>
  <v-app id="app">
    <v-main>
      <v-container v-if="!cookiesEnabled" fill-height>
        <v-row align="center" justify="center" style="display: grid; justify-content: center">
          <v-col style="text-align: center">
            <v-img :src="webMailLogo" contain height="60" />
            <v-alert v-if="cookiesEnabled" border="bottom" class="mt-3" color="primary" outlined>
              {{ error }}
            </v-alert>
            <div v-if="!cookiesEnabled" style="font-family: roboto,serif">
              <h1 style="padding: 20px">
                {{ $t('error.cookie.disabledCookies') }}
              </h1>
              <p style="padding: 20px; font-size: 18px">
                {{ $t('error.cookie.instruction') }}
                <v-btn
                  :href="browserDetection()"
                  text
                  color="#2196F3"
                  target="_blank"
                  style="padding:0;"
                  >{{
                  $t('error.cookie.learnMore')
                }}</v-btn>
              </p>
              <v-btn large rounded color="primary" @click="reloadPage()">{{
                $t('error.cookie.tryAgain')
              }}</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <router-view v-else />
    </v-main>
  </v-app>
</template>

<script>
import { localize } from 'vee-validate';
import MetaTagsMixin from '@/mixins/MetaTagsMixin';
import { getCookie, setCookie } from './util/cookie';

export default {
  name: 'App',
  mixins: [MetaTagsMixin],
  data: () => ({
    cookiesEnabled: true,
  }),
  mounted() {
    this.InitLocale();
    if (!navigator.cookieEnabled) {
      this.cookiesEnabled = false;
    }
  },

  methods: {
    InitLocale() {
      let locale = process.env.VUE_APP_I18N_LOCALE;
      if (this.$route.params.lang) {
        locale = this.$route.params.lang;
      } else if (navigator.language) {
        locale = navigator.language.slice(0, 2);
      } else if (getCookie('zwma-lang')) {
        locale = getCookie('zwma-lang');
      }
      setCookie('zwma-lang', locale, 30, process.env.VUE_APP_NEW_WEBMAIL);
      this.$i18n.locale = locale;
      localize(locale);
      this.setMetaTags();
    },
    reloadPage() {
      window.location.reload();
    },
    browserDetection() {
      if (!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)) {
        return 'https://support.google.com/accounts/answer/61416?hl=en';
      } else if (typeof InstallTrigger !== 'undefined') {
        return 'https://support.mozilla.org/en-US/kb/block-websites-storing-cookies-site-data-firefox#w_block-cookies-for-all-sites';
      } else if (
        /constructor/i.test(window.HTMLElement)
          || (function testSafariAgent(p) {
            return p.toString() === '[object SafariRemoteNotification]';
            // eslint-disable-next-line
        }(!window.safari || (typeof safari !== 'undefined' && safari.pushNotification)))
      ) {
        return 'https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac';
      }
      return 'https://support.google.com/accounts/answer/61416?hl=en';
    },
  },
  computed: {
    webMailLogo() {
      return process.env.VUE_APP_WEBMAIL_LOGO || '/assets/zone_ee.svg';
    },
  },
};
</script>
