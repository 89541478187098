<template>
  <v-app>
    <v-main>
      <v-container fluid class="pa-5">
        <router-view />
      </v-container>
      <AuthFooter class="px-3 mt-2" v-if="$vuetify.breakpoint.smAndDown" />
    </v-main>

  <Snackbar/>
  </v-app>
</template>

<script>
import AuthFooter from '../components/AuthFooter.vue';
import Snackbar from '../components/Snackbar.vue';

export default {
  components: {
    AuthFooter,
    Snackbar,
  },

};
</script>
