// converts hex values from server to ArrayBuffer values
function decodeChallengeOptions(registrationOptions) {
  const fromHex = (str) => new Uint8Array(str.match(/../g).map((h) => parseInt(h, 16))).buffer;

  const decoded = { ...registrationOptions, challenge: fromHex(registrationOptions.challenge) };

  if (decoded.user) {
    decoded.user = {
      ...decoded.user,
      id: Uint8Array.from(registrationOptions.user.id, (c) => c.charCodeAt(0)).buffer,
    };
  }

  // excludeCredentials from hex to Uint8Array
  ['excludeCredentials', 'allowCredentials'].forEach((key) => {
    if (registrationOptions[key] && registrationOptions[key].length) {
      decoded[key] = registrationOptions[key].map((entry) => ({
        id: fromHex(entry.rawId),
        type: entry.type,
        transports: entry.transports,
      }));
    }
  });

  return decoded;
}
// converts ArrayBuffer values to hex strings
function encodeChallengeResponse(challenge, attestationResult) {
  const toHex = (arrayBuffer) => new Uint8Array(arrayBuffer).reduce((a, b) => a + b.toString(16).padStart(2, '0'), '');

  const res = {
    rawId: toHex(attestationResult.rawId),
    challenge,
  };

  ['clientDataJSON', 'attestationObject', 'signature', 'authenticatorData'].forEach((key) => {
    if (attestationResult.response[key]) {
      res[key] = toHex(attestationResult.response[key]);
    }
  });

  return res;
}

export {
  decodeChallengeOptions,
  encodeChallengeResponse,
};
