<template>
  <footer class="site-footer">
    <v-row v-if="$vuetify.breakpoint.mdAndUp" justify="center" >
      <v-col class="d-flex justify-end pr-0"  cols="12" lg="9" md="12" xl="7">
        <a :href="$t(`installation-specific.${installationTransKey}.links.help`)"
           class="py-3 px-2 mr-2"
           target="_blank">
          {{ $t('general.help') }}</a
        >
        <a :href="$t(`installation-specific.${installationTransKey}.links.privacy`)"
           class="py-3 px-2 mr-2"
           target="_blank">
          {{ $t('general.privacy') }}</a
        >
        <a :href="$t(`installation-specific.${installationTransKey}.links.terms`)"
           class="pt-3" target="_blank">{{
            $t('general.terms')
          }}</a>
      </v-col>
    </v-row>
    <v-row
        v-else
        class="site-footer__mobile"
        justify="space-between"
    >
      <v-col align-self="center" class="site-footer__mobile-col" cols="6" sm="auto">
        <Lang mobile/>
      </v-col>
      <v-col align-self="center" class="site-footer__mobile-col" cols="6" sm="auto">
        <a :href="$t(`installation-specific.${installationTransKey}.links.help`)"
           target="_blank"> {{
            $t('general.help')
          }}</a>
      </v-col>
      <v-col align-self="center" class="site-footer__mobile-col" cols="6" sm="auto">
        <a :href="$t(`installation-specific.${installationTransKey}.links.privacy`)"
           target="_blank">
          {{ $t('general.privacy') }}</a>
      </v-col>
      <v-col align-self="center" class="site-footer__mobile-col" cols="6" sm="auto">
        <a :href="$t(`installation-specific.${installationTransKey}.links.terms`)"
           target="_blank">{{ $t('general.terms') }}</a>
      </v-col>
    </v-row>
  </footer>
</template>

<script>
import Lang from './Lang.vue';

export default {
  components: {
    Lang,
  },
};
</script>
