import axios from 'axios';

export default {
  async isOldEmail(form) {
    if (!process.env.VUE_APP_API_EMAIL_URL) return false;

    try {
      const old = process.env.VUE_APP_OLD_WEBMAIL_PARAMS.split(',');
      const { data } = await axios.get(
        process.env.VUE_APP_API_EMAIL_URL,
        { params: { email: form } },
      );
      if (old.some((oldEmail) => data.includes(oldEmail))) {
        return false;
      }
    } catch (e) {
      console.error(e);
      // TODO: Add user notification
    }
    return true;
  },
};
