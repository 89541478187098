<template>
    <validation-observer ref="observer" v-slot="{ handleSubmit }" slim>
        <v-card-title class="software__title">
            <v-icon color="#0d0d1a" class="pr-3" large>mdi-lock-outline</v-icon>
            {{ $t('login.2fa.title') }}
        </v-card-title>
        <v-divider class="mx-4"/>
        <v-card-text class="software__text">
            <p>{{ $t('login.2fa.instruction') }}</p>
            <p class="text-align-center text--error" v-if="error">{{ error }}</p>

            <v-form novalidate @submit.prevent="() => {}">
                <validation-provider
                    rules="required|max:6"
                    vid="code"
                    v-slot="{ errors }"
                    slim
                    :name="$t('login.2fa.code')"
                >
                    <v-text-field
                        :label="$t('login.2fa.code')"
                        ref="codeInput"
                        id="code"
                        name="code"
                        outlined
                        dense
                        autocomplete="off"
                        type="number"
                        v-model="code"
                        :data-lpignore="true"
                        :error-messages="errors"
                        @keyup.enter="handleSubmit(validateCode)"
                    />
                </validation-provider>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-row class="pt-5" no-gutters justify="space-between" align-content="end">
                <v-col class="d-flex align-center">
                    <v-btn
                        color="#0890FF"
                        class="font-weight-bold text-capitalize text-h6"
                        plain
                        @click="cancelTwoFactorAuthentication"
                    >
                        {{ $t('general.cancel') }}
                    </v-btn>
                </v-col>
                <v-col class="d-flex justify-end">
                    <v-btn
                        color="info"
                        type="submit"
                        id="submit"
                        :disabled="!isValid"
                        large
                        :loading="authenticating"
                        @click="handleSubmit(validateCode)"
                    >
                        {{ $t('general.submit') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </validation-observer>
</template>

<script>
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { max, required } from 'vee-validate/dist/rules';

extend('required', { ...required });
extend('max', { ...max });

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    twoData: {
      type: Object,
    },
  },
  data() {
    return {
      code: '',
      authenticating: false,
      body: {},
      error: '',
    };
  },
  computed: {
    isValid() {
      return this.code.length === 6;
    },
  },

  methods: {
    async validateCode() {
      this.error = '';
      this.authenticating = true;
      this.body = {
        code: this.code,
        id: this.twoData.id,
      };

      try {
        const { data } = await this.$http.post('/auth/2fa', this.body);
        window.location.href = `${process.env.VUE_APP_NEW_WEBMAIL}${data.path}`;
      } catch (err) {
        if (err.response) {
          console.error(err.response.data.message, err.response.data?.errors ?? []);
          // if (err.response.data.errors?.length > 0) {
          //   this.$refs.observer.setErrors({
          //     code: err.response.data.errors,
          //   });
          //   return;
          // }
          this.$refs.observer.setErrors({
            code: [err.response.data.message],
          });
        } else {
          this.error = this.$t('login.2fa.error');
        }
        this.authenticating = false;
      }
    },
    cancelTwoFactorAuthentication() {
      this.$emit('stepBack');
    },
  },
  mounted() {
    this.$refs.codeInput.focus();
  },
};
</script>
