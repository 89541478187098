import Vue from 'vue';
import Router from 'vue-router';

// New Layouts
import AuthLayout from '@/layouts/AuthLayout.vue';
import Auth from '@/views/Auth.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: AuthLayout,
      children: [
        {
          path: '/:lang?',
          name: 'Auth',
          component: Auth,
          meta: {
            skipPrefixCheck: true,
          },
        },
      ],
    },
  ],
});

export default router;
