// eslint-disable-next-line no-restricted-imports
import uri from '@/util/uri';

export default {
  install(Vue) {
    Object.defineProperties(Vue.prototype, {
      $uri: {
        get() {
          return uri;
        },
      },
    });
  },
};
