<template>
  <div>
    <v-card-title class="software__title">
      <v-icon color="#0d0d1a" class="pr-3" large>mdi-lock-outline</v-icon>
      {{ $t('login.hardware.title') }}
    </v-card-title>
    <v-divider class="mx-4" />
    <v-card-text>
      <p>{{ $t('login.hardware.instruction') }}</p>
      <p class="text-align-center text--error" v-if="error">{{ error }}</p>

      <v-container class="px-0" fluid>
        <v-row align="center" dense>
          <v-col cols="12" md="4">
            <span style="word-break: break-word;">
            {{ $t('login.hardware.chooseWebAuthnAuthenticator')  }}
            </span>
          </v-col>
          <v-col>
            <v-select
                id="authenticator"
                v-model="selectedAuthenticator"
                :items="availableAuthenticators"
                :label="$t('login.hardware.chooseWebAuthnAuthenticator')"
                dense
                flat
                hide-details
                item-text="name"
                item-value="value"
                outlined
                solo
            />
          </v-col>
        </v-row>
      </v-container>

    </v-card-text>
    <v-card-actions class="d-flex justify-space-between">
      <v-btn @click="cancelTwoFactorAuthentication">
        {{ $t('cancel') }}
      </v-btn>
      <v-btn
          color="info"
          @click="login"
      >
        <v-progress-circular width="3" indeterminate v-if="loading"/>
        <template v-else>{{ $t('login.hardware.login') }}</template>
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import webAuthN from '@/services/webauthn.service';
import { getCookie, setCookie } from '@/util/cookie';

export default {
  data() {
    return {
      selectedAuthenticator: 'cross-platform',
      loading: false,
      error: '',
      cookieName: 'ZWM-Auth-selectedHardwareAuthenticator',
    };
  },
  props: {
    twoData: {
      type: Object,
    },
  },

  methods: {
    cancelTwoFactorAuthentication() {
      this.$emit('stepBack');
    },
    async login() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.error = '';
      try {
        const { id } = this.twoData;
        setCookie(this.cookieName, this.selectedAuthenticator, 9999);
        const { data: { path } } = await webAuthN.login(this.selectedAuthenticator, id);
        window.location.href = `${process.env.VUE_APP_NEW_WEBMAIL}${path}`;
      } catch (e) {
        console.error(e);
        this.error = this.$t('login.2fa.error');
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    availableAuthenticators() {
      return [
        {
          name: this.$t('login.hardware.authenticators.platform'),
          value: 'platform',
        },
        {
          name: this.$t('login.hardware.authenticators.cross-platform'),
          value: 'cross-platform',
        },
      ];
    },
  },
  mounted() {
    this.selectedAuthenticator = getCookie(this.cookieName);
  },
};
</script>
