<template>
  <validation-observer ref="observer" slim v-slot="{ handleSubmit }">
    <p v-if="activeStep === 2"
       class="pt-10 pb-8 text-h6 font-weight-regular authentication-block__loginEmail">
      {{ login.email }}
    </p>
    <v-form class="mt-6" @submit.prevent="handleSubmit(authenticateStep)">
      <validation-provider
        rules="required|email"
        :style="userNameFieldStyles"
        vid="email"
        :name="$t('login.email')"
        v-slot="{ errors }"
        slim
      >
        <v-text-field
          v-model="login.email"
          type="email"
          :label="$t('login.email')"
          name="username"
          autocomplete="username"
          :data-lpignore="activeStep > 1"
          :readonly="activeStep !== 1"
          outlined
          solo
          dense
          :error-messages="errors"
        />
        <input v-show="false" type="password" v-model="login.password" name="password" />
      </validation-provider>

      <validation-provider
        rules="required"
        :name="$t('login.password')"
        vid="password"
        v-slot="{ errors }"
        v-if="activeStep === 2"
        slim
      >
        <v-text-field
          v-model="login.password"
          id="password"
          :label="$t('login.password')"
          outlined
          dense
          solo
          data-vv-as="password"
          data-cy="passwordField"
          :type="showPassword ? 'text' : 'password'"
          name="text"
          :error-messages="errors"
          :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
          @click:append="showPassword = !showPassword"
          ref="passwordField"
        />
      </validation-provider>

      <p class="authentication-block-info"
         :class="{'pt-10 mt-10': $vuetify.breakpoint.mdAndUp,
          'pt-5 mt-5': $vuetify.breakpoint.smAndDown}">
        {{ $t('general.privacy-info.text') }}
      </p>

      <v-row class="pt-5" no-gutters justify="space-between" align-content="end">
        <v-col class="d-flex align-center">
          <v-btn
            v-if="activeStep === 2"
            color="#0890FF"
            class="font-weight-bold text-capitalize text-h6 pl-0"
            plain
            data-cy="back"
            @click="stepBack"
          >
            {{ $t('login.back') }}
          </v-btn>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn
            color="info"
            type="submit"
            :disabled="!isValid"
            large
            data-cy="submit"
            :loading="authenticating"
          >
            {{ $t('login.next') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { isValidEmailFormat } from '@/util';
import { getCookie, setCookie } from '@/util/cookie';
import authService from '../services/auth';

extend('required', {
  ...required,
});

extend('email', {
  validate: (value) => isValidEmailFormat(value),
});
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      activeStep: 1,
      authenticating: false,
      showPassword: false,
      login: {
        email: '',
        password: '',
      },
      accounts: [],
      emailCookieList: [],
    };
  },
  computed: {
    isValid() {
      return isValidEmailFormat(this.login.email) || this.login.password;
    },
    userNameFieldStyles() {
      if (this.activeStep === 1) return '';
      return {
        position: 'absolute',
        top: -10000000,
        left: -10000000,
      };
    },
  },
  methods: {
    stepBack() {
      this.activeStep -= 1;
      this.$emit('stepBack');
    },
    authenticateStep() {
      if (this.activeStep === 2) {
        this.authenticate();
      } else {
        this.validateEmail();
      }
    },
    async authenticate() {
      this.authenticating = true;
      try {
        const { data } = await this.$http.post('auth', this.login);
        if (typeof data.twoFactor !== 'undefined') {
          this.$emit('nextStep');
          this.$emit('data', data);
          // Start two-factor auth process
        } else {
          window.location.href = `${process.env.VUE_APP_NEW_WEBMAIL}${data.path}`;
        }
      } catch (e) {
        console.error(e);
        if (e.response?.status === 419) return;

        if (e.response && e.response.status !== 500) {
          this.$refs.observer.setErrors({
            password: [e.response.data.message],
          });
        } else {
          this.$refs.observer.setErrors({
            password: [this.$t('login.errors.internal')],
          });
        }
      } finally {
        this.authenticating = false;
      }
    },
    setEmailCookie(cemail, isNew) {
      let array = [];
      if (getCookie(process.env.VUE_APP_WEBMAIL_EMAIL_COOKIE) !== null) {
        array = JSON.parse(getCookie(process.env.VUE_APP_WEBMAIL_EMAIL_COOKIE));
      }

      const cookieEmail = {
        email: cemail,
        isNew,
      };
      array.push(cookieEmail);
      const Json = JSON.stringify(array);
      setCookie(process.env.VUE_APP_WEBMAIL_EMAIL_COOKIE, Json, 1);
    },
    async validateEmail() {
      let isFromNewWebmail = false;
      const emailCookieData = this.searchEmail(this.login.email);

      if (emailCookieData === undefined) {
        isFromNewWebmail = await authService.isOldEmail(this.login.email);

        if (isFromNewWebmail) {
          this.setEmailCookie(this.login.email, isFromNewWebmail);
        }
      } else {
        isFromNewWebmail = emailCookieData.isNew;
      }

      if (isFromNewWebmail) {
        this.$refs.observer.reset();
        this.activeStep += 1;
        this.$emit('nextStep');
        this.$nextTick(() => {
          this.$refs.passwordField.focus();
        });
      } else {
        window.location.replace(
          `${process.env.VUE_APP_OLD_WEBMAIL}${`?usrname=${this.login.email}`}`,
        );
      }
    },
    searchEmail(cemail) {
      if (getCookie(process.env.VUE_APP_WEBMAIL_EMAIL_COOKIE) === null) return undefined;
      const array = JSON.parse(getCookie(process.env.VUE_APP_WEBMAIL_EMAIL_COOKIE));
      return array.find((o) => o.email === cemail);
    },
  },
  created() {
    if (this.$route.query.reason && this.$route.query.reason.length) {
      if (this.$route.query.email && this.$route.query.email.length) {
        this.login.email = this.$route.query.email;
        this.activeStep = 2;
      }
    }
  },
};
</script>
