/* eslint no-param-reassign: ["error", { "props": false }] */
import axios from 'axios';
// eslint-disable-next-line no-restricted-imports
import api from '../services/api';

export default {
  install(Vue) {
    Object.defineProperties(Vue.prototype, {
      $id: {
        get() {
          return axios.create({
            withCredentials: true,
            baseURL: process.env.VUE_APP_ID_AUTH_BASE_URL,
          });
        },
      },
      $http: {
        get() {
          return api;
        },
      },
    });
  },
};
